import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { StyledContainer, SectionTitle } from "../sections/sectionsStyles"

const HomeShowCase = ({
  children,
  image,
  fileName,
  title,
  titleSpan,
  page,
  home,
}) => (
  <>
    <GatsbyImage
      image={image}
      alt={fileName}
      style={{
        maxHeight: `${home ? "100vh" : "calc(100vh - 80px)"}`, // maxHeight: "calc(100vh - 80px)",
        height: `${page ? "15rem" : "auto"}`,
        width: "auto",
      }}
    />
    <StyledContainer>
      <SectionTitle style={{ marginTop: "5rem" }}>
        {title}
        {titleSpan && (
          <span style={{ color: "#32620e" }}>{` ${titleSpan}`}</span>
        )}
      </SectionTitle>
      <div>{children}</div>
    </StyledContainer>
  </>
)

export default HomeShowCase
