import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { Section } from "../common/layout/Containers"
import { GetStartedContainer, FeatureText, LinkStyled } from "./sectionsStyles"
import HomeShowCase from "../common/HomeShowCase"

const Hero = ({ page }) => {
  const { file } = useStaticQuery(query)
  const image = getImage(file.childImageSharp.gatsbyImageData)

  return (
    <Section id="top" style={{ paddingTop: "0" }}>
      <HomeShowCase
        image={image}
        fileName="vers le mari de ta destinée"
        title="Pourquoi"
        titleSpan="Vers le mari de ta destinée ?"
        page={page}
        home
      >
        <FeatureText>
          Avoir donné ta vie à Dieu est sans le moindre doute la décision la
          plus importante, la plus puissante et la plus « porteuse de vie » que
          tu aies jamais prise. En effet, tu as assuré ton éternité au paradis,
          dans la présence de ton Père céleste, où tu ne connaitras plus jamais
          aucune souffrance. Cependant, il te reste encore une décision d’une
          importance presque aussi capitale à prendre, car elle déterminera
          comment tu vivras ton séjour sur terre : celle du choix de ton futur
          mari.
        </FeatureText>
        <FeatureText>
          Effectivement, l’homme avec qui tu choisiras de passer le reste de ta
          vie sera soit une source d’épanouissement et un avant-gout du ciel,
          soit un charbon ardent sur ta tête et un enfer sur terre. De fait,
          Dieu lui-même a choisi la métaphore du mariage pour illustrer sa
          relation avec son peuple : le Christ représentant l’époux et l’Église,
          sa fiancée (Apocalypse 19.6-8).
        </FeatureText>
        <FeatureText>
          Alors, comment faire le bon choix ? Comment évaluer tes critères ?
          Quelle place donner à Dieu dans ce processus ? Que faire si ce que
          Dieu veut pour toi s’oppose à ta propre volonté ? Comment prier pour
          ton futur mari ? Et comment le reconnaitre le moment venu ?
        </FeatureText>
        {page && (
          <>
            <FeatureText>
              C’est pour répondre à toutes ces questions, et toutes les autres
              que tu aurais sur le sujet, que Sammantha Thom a organisé le
              voyage <strong>{` Vers le mari de ta destinée`}</strong>. Ce
              voyage a commencé avec la publication et l’offre gratuite de son
              e-book
              <strong>
                {
                  " Les critères d’un bon mari, l’avis de 100 épouses chrétiennes "
                }
              </strong>
              ; et se poursuit depuis avec la publication de plusieurs autres
              ressources (vidéos sur YouTube, rencontres en direct sur
              Instagram, discussions avec des invités particuliers, articles,
              etc.).
            </FeatureText>
            <FeatureText>
              Sur cette page, tu pourras suivre l’évolution du voyage, ne
              manquer aucune escale, faire l’arrêt obligatoire des{" "}
              <strong>{` 31 jours de prière pour ton futur mari`}</strong>, son
              livre qui sortira en automne 2021, et t’assurer d’arriver à
              destination : au bras du mari de ta destinée.
            </FeatureText>
            <FeatureText>
              Alors, prends place, attache ta ceinture et rendez-vous à la
              réalisation du plan de mariage que Dieu a formé pour toi !
            </FeatureText>
          </>
        )}
      </HomeShowCase>
      {!page && (
        <GetStartedContainer>
          <LinkStyled
            to="/vers-le-mari-de-ma-destinee/"
            style={{ textDecoration: "none" }}
          >
            En savoir plus
          </LinkStyled>
        </GetStartedContainer>
      )}
    </Section>
  )
}

export default Hero

const query = graphql`
  {
    file(
      sourceInstanceName: { eq: "book" }
      name: { eq: "marche-vers-le-mari-de-ta-destinee-2560x1440" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
  }
`
