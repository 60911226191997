import * as React from "react"

import Layout from "../components/common/layout/Layout"
import Hero from "../components/sections/Hero"
import Seo from "../components/common/SEO"

const Page = () => {
  return (
    <Layout>
      <Seo
        title="Pourquoi vers le mari de ta destinée"
        description="Le voyage vers le mari de ta destinée"
      />
      <Hero page />
    </Layout>
  )
}

export default Page
